import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  startPageChanger,
  installDifficultyGame,
  setCurrentDeck,
  setCurentTechDeck,
} from "../redux/features/stats/statsSlice.js";
import cardHover from "../sound/hackCardHoverFX.wav";

const Difficulty = ({}) => {
  const [targetDifficulty, setTargetDifficulty] = useState("easy");
  const soundOn = useSelector((state) => state.stats.soundOn);
  const realHp = useSelector((state) => state.stats.realHp);
  const [cardHoverMouseOver, setCardHoverMouseOver] = useState(false);

  const playSoundCardHover = () => {
    if (soundOn === true) {
      const audio = new Audio(cardHover);
      audio.play();
      setCardHoverMouseOver(true);
    }
  };

  const stopSoundCardHover = () => {
    setCardHoverMouseOver(false);
  };

  const easyDeck = useSelector((state) => state.stats.easyDeck);
  const mediumDeck = useSelector((state) => state.stats.mediumDeck);
  const hardDeck = useSelector((state) => state.stats.hardDeck);
  const techDeck = useSelector((state) => state.stats.techDeck);
  const difficultyGame = useSelector((state) => state.stats.difficultyGame);

  ///Generation cards
  //purple 1
  const offensiveCards = useSelector((state) => state.stats.offensiveCards);
  //blue 1
  const defensiveCards = useSelector((state) => state.stats.defensiveCards);
  //yellow 7
  const nodeCards = useSelector((state) => state.stats.nodeCards);
  //upgrade orange 1
  const relicCards = useSelector((state) => state.stats.relicCards);
  // heal none 1
  const healthCards = useSelector((state) => state.stats.healthCards);
  // red 5
  const enemies = useSelector((state) => state.stats.enemies);
  // 1 - 3
  const bosses = useSelector((state) => state.stats.bosses);

  const shops = useSelector((state) => state.stats.shops);

  const win = useSelector((state) => state.stats.win);

  const bfCard = useSelector((state) => state.stats.bfCard);

  // normal  - 17 cards deck = 5 red  , 1 blue , 7 yellow ,  1 purple ,  1 orange ,  1 tor Browser , hpUpgrade
  // medium -  18 card deck  = 7 red , 1 blue , 7 yellow, 1 purple , 1  blue , 1 tor , 1 hp upgrade ,
  // hard  -  18   = 7 red , 1 blue , 7 yellow ,  1 purple , 1 tor , 1 hp

  // const genCardFunction = () => {
  //   const offensiveCardsCopy = [...offensiveCards];
  //   const defensiveCardsCopy = [...defensiveCards];
  //   const nodeCardsCopy = [...nodeCards];

  //   function mergeRandomElementsFromArray(arr1, arr2, arr3) {
  //     const mergedArray = [];
  //     mergedArray.push(...getRandomElementsFromArray(arr1, 2)); // добавляем 2 случайных элемента из первого массива
  //     mergedArray.push(...getRandomElementsFromArray(arr2, 2)); // добавляем 2 случайных элемента из второго массива
  //     mergedArray.push(...getRandomElementsFromArray(arr3, 2)); // добавляем 2 случайных элемента из третьего массива
  //     return mergedArray;
  //   }

  //   function getRandomElementsFromArray(arr, count) {
  //     if (!arr || arr.length < 1) {
  //       return []; // если массив не определен или пустой, возвращаем пустой массив
  //     }

  //     const shuffledArray = arr.sort(() => 0.5 - Math.random()); // перемешиваем массив случайным образом
  //     // return arr.sort(() => 0.5 - Math.random()).slice(0, 2);

  //     return shuffledArray.slice(0, count); // возвращаем первые count элементов
  //   }
  //   function shuffleArray(arr) {
  //     for (let i = arr.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [arr[i], arr[j]] = [arr[j], arr[i]];
  //     }
  //     return arr;
  //   }

  //   const mergedArray = mergeRandomElementsFromArray(
  //     offensiveCardsCopy,
  //     defensiveCardsCopy,
  //     nodeCardsCopy
  //   );
  //   shuffleArray(mergedArray);
  //   ////console.log(mergedArray); // выводит массив с 6 случайными элементами из трех исходных массивов

  //   ///
  // };
  const genCardOnDeckFunction = () => {
    const offensiveCardsCopy = [...offensiveCards];
    const defensiveCardsCopy = [...defensiveCards];
    const nodeCardsCopy = [...nodeCards];
    const relicCardsCopy = [...relicCards];
    const healthCardsCopy = [...healthCards];
    const enemiesCopy = [...enemies];
    const bossesCopy = [...bosses];
    const shopsCopy = [...shops];
    const winCopy = [...win];

    const fullDeck = [];
    for (let i = 0; i < 3; i++) {
      function mergeRandomElementsFromArray(
        arr1,
        arr2,
        arr3,
        arr4,
        arr5,
        arr6
        // arr7
      ) {
        //tunning difficulty
        const mergedArray = [];
        if (difficultyGame === "easy") {
          mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
          mergedArray.push(...getRandomElementsFromArray(arr2, 5)); // blue def
          mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
          mergedArray.push(...getRandomElementsFromArray(arr4, 1)); // orange relic 1
          mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
          mergedArray.push(...getRandomElementsFromArray(arr6, 3)); // enemy 3
          ////console.log("easy");
        }
        if (difficultyGame === "medium") {
          mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
          mergedArray.push(...getRandomElementsFromArray(arr2, 5)); // blue def
          mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
          mergedArray.push(...getRandomElementsFromArray(arr4, 1)); // orange relic 1
          mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
          mergedArray.push(...getRandomElementsFromArray(arr6, 3)); // enemy 3
          // mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
          // mergedArray.push(...getRandomElementsFromArray(arr2, 3)); // blue def
          // mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
          // mergedArray.push(...getRandomElementsFromArray(arr4, 1)); // orange relic 1
          // mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
          // mergedArray.push(...getRandomElementsFromArray(arr6, 5)); // enemy 5
          ////console.log("medium");
        }
        if (difficultyGame === "hard") {
          mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
          mergedArray.push(...getRandomElementsFromArray(arr2, 5)); // blue def
          mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
          mergedArray.push(...getRandomElementsFromArray(arr4, 1)); // orange relic 1
          mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
          mergedArray.push(...getRandomElementsFromArray(arr6, 3)); // enemy 3
          // mergedArray.push(...getRandomElementsFromArray(arr1, 2)); // purple offen
          // mergedArray.push(...getRandomElementsFromArray(arr2, 1)); // blue def
          // mergedArray.push(...getRandomElementsFromArray(arr3, 6)); // yellow node
          // mergedArray.push(...getRandomElementsFromArray(arr4, 1)); // orange relic 1
          // mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
          // mergedArray.push(...getRandomElementsFromArray(arr6, 5)); // enemy 5
          ////console.log("hard");
        }
        // mergedArray.push(...getRandomElementsFromArray(arr7, 1)); // boss 1
        //tor browser  + Shop HP
        return mergedArray;
      }

      function getRandomElementsFromArray(arr, count) {
        if (!arr || arr.length < 1) {
          return []; // если массив не определен или пустой, возвращаем пустой массив
        }

        const shuffledArray = arr.sort(() => 0.5 - Math.random()); // перемешиваем массив случайным образом
        // return arr.sort(() => 0.5 - Math.random()).slice(0, 2);

        return shuffledArray.slice(0, count); // возвращаем первые count элементов
      }
      function shuffleArray(arr) {
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
      }

      const mergedArray = mergeRandomElementsFromArray(
        offensiveCardsCopy,
        defensiveCardsCopy,
        nodeCardsCopy,
        relicCardsCopy,
        healthCardsCopy,
        enemiesCopy
        // shopCopy
        // bossesCopy
      );
      shuffleArray(mergedArray);
      ////console.log(mergedArray); // выводит массив с случайными элементами из трех исходных массивов
      mergedArray.push(...getRandomElementsFromArray(bossesCopy, 1)); // boss 1
      mergedArray.push(...getRandomElementsFromArray(shopsCopy, 2)); // shop + heal buff

      fullDeck.push(...mergedArray);
    }
    fullDeck.splice(-2);
    fullDeck.push(winCopy[0]); // win card
    // dispatch(setCurrentDeck(mergedArray));
    ////console.log(fullDeck);

    dispatch(setCurrentDeck(fullDeck));
    ///
  };


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentDeck(easyDeck));
    dispatch(setCurentTechDeck(techDeck));
    // genCardFunction();
    // fullDeck();
  }, []);

  useEffect(() => {
    genCardOnDeckFunction();
    // genCardOnTechFunction();
  }, [difficultyGame]);

  useEffect(() => {
    if (realHp <= 0) {
      genCardOnDeckFunction();
      // genCardOnTechFunction();
    }
  }, [realHp]);

  return (
    <div className="flex gap-2 diffSelector">
      <button
        onMouseOver={playSoundCardHover}
        onMouseOut={stopSoundCardHover}
        onClick={() => {
          dispatch(installDifficultyGame("easy"));
          // dispatch(setCurrentDeck(easyDeck));
          setTargetDifficulty("easy");
        }}
        className={`difficulty bg-white ${
          targetDifficulty === "easy" ? "active" : ""
        }`}
        // className="difficulty bg-white active"
      >
        Easy
      </button>
      <button
        onMouseOver={playSoundCardHover}
        onMouseOut={stopSoundCardHover}
        onClick={() => {
          dispatch(installDifficultyGame("medium"));
          // dispatch(setCurrentDeck(mediumDeck));
          setTargetDifficulty("medium");
        }}
        className={`difficulty bg-white ${
          targetDifficulty === "medium" ? "active" : ""
        }`}
      >
        Medium
      </button>
      <button
        onMouseOver={playSoundCardHover}
        onMouseOut={stopSoundCardHover}
        onClick={() => {
          dispatch(installDifficultyGame("hard"));
          // dispatch(setCurrentDeck(hardDeck));
          setTargetDifficulty("hard");
        }}
        className={`difficulty bg-white ${
          targetDifficulty === "hard" ? "active" : ""
        }`}
      >
        Hard
      </button>
    </div>
  );
};

export default Difficulty;
