import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { activeAchievements,achievementsStartReducer } from "../redux/features/stats/statsSlice.js";

const Achievements = ({}) => {
  const achievements = useSelector((state) => state.stats.achievements);
  const achievementsListFresh = useSelector((state) => state.stats.achievementsListFresh);
  const storedAchievementsList = JSON.parse(
    localStorage.getItem("achievementsList")
  );
  const countTrueValues = storedAchievementsList?.reduce((count, achievement) => {
    if (achievement.complete) {
      count++;
    }
    return count;
  }, 0);
  const generateCards = () => {
    return storedAchievementsList?.map((achievement) => {
      return (
        <div
        key={achievement.name}
          className={`flex flex-col ach ${
            achievement.complete === false ? "opacity-50" : ""
          }`}
        >
          <img
            src={`${
              achievement.complete === false
                ? "https://assets.codepen.io/217233/hack--ach.svg"
                : "https://assets.codepen.io/217233/hack--achComplete.svg"
            }`}
            className="h-8 w-8 mb-3"
            alt=""
          />
          <span className="text-xl">{achievement.name}</span>
          <span className="text-sm text-description pr-6">
            {achievement.description}
          </span>
        </div>
        //   <div className="flex flex-col ach">
        //   <img
        //     src="https://assets.codepen.io/217233/hack--achComplete.svg"
        //     alt=""
        //     className="h-8 w-8 mb-3"
        //   />
        //   <span className="text-xl">One down</span>
        //   <span className="text-sm text-description pr-6">
        //     Terminate a node
        //   </span>
        // </div>

        // <div key={achievement.name}>
        //   <h3>{achievement.name}</h3>
        //   <p>{achievement.description}</p>
        //   {/* <p>{achievement.complete ? "Completed" : "Not completed"}</p> */}
        // </div>
      );
    });
  };
  const dispatch = useDispatch();

  return (
    <div
      className={`game_intro__achievements text-white text-2xl h-screen flex items-center h-full ${
        achievements === true ? "active" : ""
      }`}
    >
      <div className="inner">
        <div className="flex justify-between items-center mb-12">
          <h4
            onClick={() => {
              dispatch(activeAchievements(false));
            }}
            className="backArrow"
          >
            <svg
              fill="none"
              height="21"
              viewBox="0 0 24 21"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M4.15132 11.4688L11.2171 18.5329C11.3991 18.7148 11.5012 18.9615 11.5012 19.2188C11.5012 19.476 11.3991 19.7227 11.2171 19.9046C11.0352 20.0865 10.7885 20.1887 10.5313 20.1887C10.274 20.1887 10.0273 20.0865 9.8454 19.9046L1.12665 11.1859C1.03643 11.0959 0.964855 10.989 0.916018 10.8713C0.86718 10.7536 0.842041 10.6274 0.842041 10.5C0.842041 10.3726 0.86718 10.2464 0.916018 10.1287C0.964855 10.011 1.03643 9.90412 1.12665 9.81413L9.8454 1.09538C10.0273 0.913473 10.274 0.811279 10.5313 0.811279C10.7885 0.811279 11.0352 0.913473 11.2171 1.09538C11.3991 1.27728 11.5012 1.524 11.5012 1.78125C11.5012 2.03851 11.3991 2.28522 11.2171 2.46713L4.15132 9.53125H22.1562C22.4132 9.53125 22.6596 9.63332 22.8413 9.81499C23.0229 9.99667 23.125 10.2431 23.125 10.5C23.125 10.7569 23.0229 11.0033 22.8413 11.185C22.6596 11.3667 22.4132 11.4688 22.1562 11.4688H4.15132Z"
                fillRule="evenodd"
                fill="#fff"
              ></path>
            </svg>
          </h4>
          <button
            onClick={() => {
              dispatch(activeAchievements(false));
              localStorage.setItem(
                "achievementsList",
                JSON.stringify(achievementsListFresh)
              );
              dispatch(achievementsStartReducer(achievementsListFresh));
            }}
            className="hack bg-white"
          >
            Reset
          </button>
        </div>
        <div className="flex justify-between mb-24">
          <h2>Achievements</h2>
          <div className="text-white flex w-12 items-end">
            <span className="text-2xl">{countTrueValues}</span>
            <span className="text-lightgrey text-md">/35</span>
          </div>
        </div>

        {/* <div className="grid grid-cols-4 gap-x-8 gap-y-12 overflow-y-scroll">
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              className="h-8 w-8 mb-3"
              alt=""
            />
            <span className="text-xl">Maximum penetration</span>
            <span className="text-sm text-description pr-6">
              Get any program to 9 or more penetration
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Snowden</span>
            <span className="text-sm text-description pr-6">
              Attempt to hack the CIA
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Script kiddy</span>
            <span className="text-sm text-description pr-6">
              Collect 5 upgrades in one run
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Red hat</span>
            <span className="text-sm text-description pr-6">
              Collect 10 upgrades in one run
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Black hat</span>
            <span className="text-sm text-description pr-6">
              Collect 15 upgrades in one run
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Elite hacker</span>
            <span className="text-sm text-description pr-6">
              Collect 20 upgrades in one run
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Impenetrable</span>
            <span className="text-sm text-description pr-6">
              Get any firewall module to 12 or more resistance
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Gigabyte</span>
            <span className="text-sm text-description pr-6">
              Collect 30 or more data
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Teabyte</span>
            <span className="text-sm text-description pr-6">
              Collect 100 or more data
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Petabyte</span>
            <span className="text-sm text-description pr-6">
              Collect 150 or more data
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Mine, all mine</span>
            <span className="text-sm text-description pr-6">
              Mine a network node
            </span>
          </div>
          <div className="flex flex-col ach">
            <img
              src="https://assets.codepen.io/217233/hack--achComplete.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">One down</span>
            <span className="text-sm text-description pr-6">
              Terminate a node
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Top up</span>
            <span className="text-sm text-description pr-6">Heal yourself</span>
          </div>
          <div className="flex flex-col ach">
            <img
              src="https://assets.codepen.io/217233/hack--achComplete.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">n00b</span>
            <span className="text-sm text-description pr-6">Get detected</span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Its a UNIX system!</span>
            <span className="text-sm text-description pr-6">
              Complete a stage
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">What upgrades?</span>
            <span className="text-sm text-description pr-6">
              Complete a stage with no upgrades
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Jackpot</span>
            <span className="text-sm text-description pr-6">
              Mine a node worth 14 or more data
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Kitted</span>
            <span className="text-sm text-description pr-6">
              Have a max tech deck capacity of 8 or more
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Data dump</span>
            <span className="text-sm text-description pr-6">
              Hack a Data Center
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Not so secure</span>
            <span className="text-sm text-description pr-6">
              Hack a Security beacon
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">My kung fu is stronger</span>
            <span className="text-sm text-description pr-6">
              Hack a Mainframe
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Antivirus down</span>
            <span className="text-sm text-description pr-6">
              Hack an Antivirus
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Through the fire and flame</span>
            <span className="text-sm text-description pr-6">
              Hack a Firewall
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Youve been served</span>
            <span className="text-sm text-description pr-6">Hack a Server</span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Piece of cake</span>
            <span className="text-sm text-description pr-6">Hack a Subnet</span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">We're in</span>
            <span className="text-sm text-description pr-6">
              Successfully hack an easy target
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">I am invincible</span>
            <span className="text-sm text-description pr-6">
              Successfully hack a medium target
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">There is no spoon</span>
            <span className="text-sm text-description pr-6">
              Successfully hack a hard target
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">I know Kung Fu</span>
            <span className="text-sm text-description pr-6">
              Download something from the dark web
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Dictionary attack</span>
            <span className="text-sm text-description pr-6">
              Increase your Brute Force penetration
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Absolute unit</span>
            <span className="text-sm text-description pr-6">
              Have a max integrity of 40 or more
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Fort Knox</span>
            <span className="text-sm text-description pr-6">
              Have a max integrity of 70 or more
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">They are on to you</span>
            <span className="text-sm text-description pr-6">
              Take more than 10 damage in one hit
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Skin of your teeth</span>
            <span className="text-sm text-description pr-6">
              Complete a stage with just 1 integrity remaining
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Digital don</span>
            <span className="text-sm text-description pr-6">
              Complete a stage with full integrity
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Who needs health</span>
            <span className="text-sm text-description pr-6">
              Reduce your max integrity to 5 or below
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">McKinnon would be proud</span>
            <span className="text-sm text-description pr-6">
              Successfully hack NASA on hard
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Bourne to do this</span>
            <span className="text-sm text-description pr-6">
              Successfully hack TREADSTONE on hard
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">Judgement day prevented</span>
            <span className="text-sm text-description pr-6">
              Successfully hack SKYNET on hard
            </span>
          </div>
          <div className="flex flex-col ach opacity-50">
            <img
              src="https://assets.codepen.io/217233/hack--ach.svg"
              alt=""
              className="h-8 w-8 mb-3"
            />
            <span className="text-xl">I'm sorry, Dave</span>
            <span className="text-sm text-description pr-6">
              Successfully hack HAL9000 on hard
            </span>
          </div>
        </div> */}
        <div className="grid grid-cols-4 gap-x-8 gap-y-12 overflow-y-scroll">
          {generateCards()}
        </div>
      </div>
    </div>
  );
};
export default Achievements;
