import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  activeTutorial,
  numCountsTutorial,
  resetCountsTutorial,
  achievementsReducer
} from "../redux/features/stats/statsSlice.js";
import cardHover from "../sound/hackCardHoverFX.wav";
import hackOpenShop from "../sound/hackOpenShopFX.mp3";

const TutorialPage = ({ page, index }) => {
  const { title, text, comment, gif, isActive } = page;
  const countsTutorial = useSelector((state) => state.stats.countsTutorial);
  const tutorialActive = useSelector((state) => state.stats.tutorialActive);

  const achievementsList = useSelector((state) => state.stats.achievementsList);

  

  const soundOn = useSelector((state) => state.stats.soundOn);
  const [cardHoverMouseOver, setCardHoverMouseOver] = useState(false);

  const nextClickSound = () => {
    if (soundOn === true) {
      const audio = new Audio(hackOpenShop);
      audio.play();
    }
  };

  const playSoundCardHover = () => {
    if (soundOn === true) {
      const audio = new Audio(cardHover);
      audio.play();
      setCardHoverMouseOver(true);
    }
  };

  const stopSoundCardHover = () => {
    setCardHoverMouseOver(false);
  };

  const dispatch = useDispatch();

  return (
    <div
      className={`${
        index === countsTutorial
          ? "tutorial_screen absolute active"
          : "tutorial_screen absolute"
      }`}
      // style={`${index === countsTutorial ? `` : `display: none;`}`}
      style={tutorialActive === false ? { display: "none" } : {}}

      // style={{display:'none'}}
    >
      <div
        className="gif bg-cover bg-center"
        style={{
          backgroundImage: gif,
        }}
      ></div>
      <div className="content">
        <h2>{title}</h2>
        <p className="mb-4">{text}</p>
        <p className="text-green">{comment}</p>{" "}
        <div
          onMouseOver={playSoundCardHover}
          onMouseOut={stopSoundCardHover}
          onClick={() => {
            dispatch(activeTutorial(false));
            dispatch(resetCountsTutorial());
          }}
          className="skip text-white absolute right-2 top-2 underline cursor-pointer"
        >
          {title === "Start hacking" ? `` : `Skip`}
        </div>
        {title === "Start hacking" ? (
          <button
            onMouseOver={playSoundCardHover}
            onMouseOut={stopSoundCardHover}
            onClick={() => {
              nextClickSound();
              dispatch(activeTutorial(false));
              dispatch(resetCountsTutorial());
            }}
            className="hack bg-white"
          >
            Play
          </button>
        ) : (
          <button
            onMouseOver={playSoundCardHover}
            onMouseOut={stopSoundCardHover}
            onClick={() => {
              nextClickSound();
              dispatch(numCountsTutorial());

              //achivments
              // dispatch(achievementsReducer("Snowden"));
              // dispatch(achievementsReducer("Maximum penetration"));
              // console.log(achievementsList)
            }}
            className="hack bg-white"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};
export default TutorialPage;
