import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  checkMode,
  loseRealHp,
  increaseDataHacker,
  increaseBlock,
  setAlarmDiv,
  countsTableCards,
  pushTechDeck,
  countsTechCards,
  increaseUpgradeAttack,
  deleteTechDeckCard,
  minusTechCards,
  addUpgradeTech,
  activeShop,
  activeHpUpgrade,
  setStatusWinner,
  setShakeAnimation,
  setShakeDmg,
  setCurentShop,
} from "../redux/features/stats/statsSlice.js";
import cardHover from "../sound/hackCardHoverFX.wav";
import hackTakeFX from "../sound/hackTakeFX.mp3";
import OneCardOnShop from "./OneCardOnShop";

const Shop = () => {
  const hpMax = useSelector((state) => state.stats.maxHp);
  const realHp = useSelector((state) => state.stats.realHp);
  const dataHacker = useSelector((state) => state.stats.dataHacker);
  const shop = useSelector((state) => state.stats.shop);
  const counstTechDeck = useSelector((state) => state.stats.counstTechDeck);
  const maxCounstTechDeck = useSelector((state) => state.stats.maxCounstTechDeck);
  
  // const [hidden, setHidden] = useState("cardOne");
  const soundOn = useSelector((state) => state.stats.soundOn);
  const [cardHoverMouseOver, setCardHoverMouseOver] = useState(false);

  const statusDetected = useSelector((state) => state.stats.statusDetected);
  const difficultyGame = useSelector((state) => state.stats.difficultyGame);
  const currentShop = useSelector((state) => state.stats.currentShop);
  const stage = useSelector((state) => state.stats.stage);


  ///Generation cards
  //purple 1
  const offensiveCards = useSelector((state) => state.stats.offensiveCards);
  //blue 1
  const defensiveCards = useSelector((state) => state.stats.defensiveCards);
  //yellow 7
  const nodeCards = useSelector((state) => state.stats.nodeCards);
  //upgrade orange 1
  const relicCards = useSelector((state) => state.stats.relicCards);
  // heal none 1
  const healthCards = useSelector((state) => state.stats.healthCards);
  // red 5
  const enemies = useSelector((state) => state.stats.enemies);
  // 1 - 3
  const bosses = useSelector((state) => state.stats.bosses);

  const shops = useSelector((state) => state.stats.shops);

  const genCardOnDeckFunction = () => {
    const offensiveCardsCopy = [...offensiveCards];
    const defensiveCardsCopy = [...defensiveCards];
    // const nodeCardsCopy = [...nodeCards];
    const relicCardsCopy = [...relicCards];
    // const healthCardsCopy = [...healthCards];
    // const enemiesCopy = [...enemies];
    // const bossesCopy = [...bosses];
    // const shopsCopy = [...shops];
    // const winCopy = [...win];

    const shopDeck = [];

    function mergeRandomElementsFromArray(
      arr1,
      arr2,
      arr3
      // arr4,
      // arr5,
      // arr6
      // arr7
    ) {
      const mergedArray = [];
      if (difficultyGame === "easy") {
        mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
        mergedArray.push(...getRandomElementsFromArray(arr2, 1)); // blue def
        // mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
        mergedArray.push(...getRandomElementsFromArray(arr3, 3)); // orange relic 1
        // mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
        // mergedArray.push(...getRandomElementsFromArray(arr6, 3)); // enemy 3
        //console.log("easy");
      }
      if (difficultyGame === "medium") {
        mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
        mergedArray.push(...getRandomElementsFromArray(arr2, 1)); // blue def
        // mergedArray.push(...getRandomElementsFromArray(arr3, 5)); // yellow node
        mergedArray.push(...getRandomElementsFromArray(arr3, 3)); // orange relic 1
        // mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
        // mergedArray.push(...getRandomElementsFromArray(arr6, 5)); // enemy 5
        //console.log("medium");
      }
      if (difficultyGame === "hard") {
        mergedArray.push(...getRandomElementsFromArray(arr1, 1)); // purple offen
        mergedArray.push(...getRandomElementsFromArray(arr2, 1)); // blue def
        // mergedArray.push(...getRandomElementsFromArray(arr3, 6)); // yellow node
        mergedArray.push(...getRandomElementsFromArray(arr3, 3)); // orange relic 1
        // mergedArray.push(...getRandomElementsFromArray(arr5, 1)); // none heal  1
        // mergedArray.push(...getRandomElementsFromArray(arr6, 5)); // enemy 5
        //console.log("hard");
      }
      // mergedArray.push(...getRandomElementsFromArray(arr7, 1)); // boss 1
      //tor browser  + Shop HP
      return mergedArray;
    }

    function getRandomElementsFromArray(arr, count) {
      if (!arr || arr.length < 1) {
        return []; // если массив не определен или пустой, возвращаем пустой массив
      }

      const shuffledArray = arr.sort(() => 0.5 - Math.random()); // перемешиваем массив случайным образом
      // return arr.sort(() => 0.5 - Math.random()).slice(0, 2);

      return shuffledArray.slice(0, count); // возвращаем первые count элементов
    }
    function shuffleArray(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      return arr;
    }

    const mergedArray = mergeRandomElementsFromArray(
      offensiveCardsCopy,
      defensiveCardsCopy,
      // nodeCardsCopy,
      relicCardsCopy
      // healthCardsCopy,
      // enemiesCopy
      // shopCopy
      // bossesCopy
    );
    shuffleArray(mergedArray);
    //console.log(mergedArray); // выводит массив с случайными элементами из трех исходных массивов
    // mergedArray.push(...getRandomElementsFromArray(bossesCopy, 1)); // boss 1
    // mergedArray.push(...getRandomElementsFromArray(shopsCopy, 2)); // shop + heal buff

    shopDeck.push(...mergedArray);
    // shopDeck.splice(-2);
    // fullDeck.push(winCopy[0]); // win card
    // dispatch(setCurrentDeck(mergedArray));
    //console.log(shopDeck);

    dispatch(setCurentShop(shopDeck));
    ///
  };
  
  const purpleCardFunction = () => {
    let delEffect = "delete_card";
    if (counstTechDeck === maxCounstTechDeck) {
      dispatch(
        setAlarmDiv("text-2xl mb-8 text-red-600 absolute top-12 deckLimit")
      );
      setTimeout(() => {
        dispatch(setAlarmDiv("hidden"));
      }, 2000);
    } else {
      // if (trigger > 1) {
      // dispatch(countsTableCards());

      //+
      // setHidden(delEffect);
      // }
      //+
      // dispatch(pushTechDeck(easyDeck.easyDeck));
      dispatch(countsTechCards());
    }
  };
  const playSoundCardHover = () => {
    if (soundOn === true) {
      const audio = new Audio(cardHover);
      audio.play();
      setCardHoverMouseOver(true);
    }
  };

  const stopSoundCardHover = () => {
    setCardHoverMouseOver(false);
  };

  const closeBrowserSound = () => {
    if (soundOn === true) {
      const audio = new Audio(hackTakeFX);
      audio.play();
    }
  };



  const getShopOneCard = () => {
    if (difficultyGame === "easy") {
      return currentShop.map((item, index) => {
        return <OneCardOnShop easyDeck={item} key={index} index={index} />;
      });
    }
    if (difficultyGame === "medium") {
      return currentShop.map((item, index) => {
        return <OneCardOnShop easyDeck={item} key={index} index={index} />;
      });
    }
    if (difficultyGame === "hard") {
      return currentShop.map((item, index) => {
        return <OneCardOnShop easyDeck={item} key={index} index={index} />;
      });
    }
  };

  const dispatch = useDispatch();


  useEffect(() => {
    if (stage === 3){
      genCardOnDeckFunction()
    }
    if (stage === 4){
      genCardOnDeckFunction()
    }
  }, [stage]);


  return (
    <div
      className={`game_shop text-white absolute top-0 left-0 z-20 w-full flex flex-col justify-center items-center py-30 ${
        shop ? "active" : ""
      }`}
    >
      <div className="div">
        <h2 className="text-3xl text-white w-full text-center mb-1 mt-16">
          Dark web
        </h2>
        <h3 className="text-lg text-white w-full text-center mb-12 text-description">
          Trade data for software
        </h3>
        <div className="flex item-center gap-6 mb-24">
          <div className="flex gap-2 items-center flex">
            <img
              src="https://assets.codepen.io/217233/hack-integrityicon.png"
              className="h-5"
              alt=""
            />
            <span className="flex items-end text-white">
              <span className="text-2xl">{realHp}</span>
              <span
                className="text-md text-lightgrey"
                style={{ color: "rgb(53, 70, 74)" }}
              >
                /{hpMax}
              </span>
            </span>
          </div>
          <div className="text-red-100 flex gap-2 items-center">
            <svg
              fill="none"
              height="52"
              viewBox="0 0 52 52"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 relative -top-px"
            >
              <path
                d="M22.2416 1.48711C23.194 0.534917 24.4857 0 25.8324 0C27.1792 0 28.4709 0.534917 29.4233 1.48711L50.1778 22.2416C51.13 23.194 51.6649 24.4857 51.6649 25.8324C51.6649 27.1792 51.13 28.4709 50.1778 29.4233L29.4233 50.1778C28.4709 51.13 27.1792 51.6649 25.8324 51.6649C24.4857 51.6649 23.194 51.13 22.2416 50.1778L1.48711 29.4233C0.534917 28.4709 0 27.1792 0 25.8324C0 24.4857 0.534917 23.194 1.48711 22.2416L22.2416 1.48711V1.48711Z"
                fill="#E5FF44"
              ></path>
            </svg>
            <span className="text-lime text-2xl">{dataHacker}</span>
          </div>
        </div>
      </div>
      <div className="game_shop__cards flex gap-4">
        {/* <div data-index="0" className="relative opacity-20 pointer-events-none"> */}

        {shop === true && statusDetected === false ? getShopOneCard() : null}
      
      </div>
      <div className="game_shop__exit mt-12">
        <button
          onClick={() => {
            dispatch(activeShop(false));
            closeBrowserSound();
          }}
          className="hack bg-white"
        >
          Close browser
        </button>
      </div>
      <div className="game_minimize uppercase text-white">
        <span>Minimize</span>
      </div>
    </div>
  );
};
export default Shop;
