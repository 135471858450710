import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  startPageChanger,
  newGameChanger,
  newGameChangerFalse,
  installDifficultyGame,
  setPlayerName,
  activeAchievements,
  setSoundOn,
  achievementsStartReducer,
} from "../redux/features/stats/statsSlice.js";
import Difficulty from "./Difficulty.js";
import Achievements from "./Achievements.js";
import cardHover from "../sound/hackCardHoverFX.wav";
import hackOpenShop from "../sound/hackOpenShopFX.mp3";

const StartPage = (easyDeck, index) => {
  const startPage = useSelector((state) => state.stats.startPage);
  const newGame = useSelector((state) => state.stats.newGame);
  const achievements = useSelector((state) => state.stats.achievements);
  const soundOn = useSelector((state) => state.stats.soundOn);
  const playerName = useSelector((state) => state.stats.playerName);
  const achievementsList = useSelector((state) => state.stats.achievementsList);
  const achievementsListFresh = useSelector(
    (state) => state.stats.achievementsListFresh
  );

  const [cardHoverMouseOver, setCardHoverMouseOver] = useState(false);

  const playSoundCardHover = () => {
    if (soundOn === true) {
      const audio = new Audio(cardHover);
      audio.play();
      setCardHoverMouseOver(true);
    }
  };

  const stopSoundCardHover = () => {
    setCardHoverMouseOver(false);
  };

  const inputHandler = (e) => {
    const value = e.currentTarget.value;
    dispatch(setPlayerName(value));
  };

  const startSound = () => {
    if (soundOn === true) {
      const audio = new Audio(hackOpenShop);
      audio.play();
    }
  };
  const [newGameOptions, setNewGameOptions] = useState(
    "hack--pattern absolute top-0  h-full"
    // "hack--pattern absolute top-0 right-0 h-full active"
  );
  const [newGameOptionsStyle, setNewGameOptionsStyle] = useState(
    "game_intro__newgame text-white text-2xl"
  );

  const dispatch = useDispatch();

  //console.log("startPage", startPage, "newGame", newGame);

  function generateRandomString() {
    const characters =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    dispatch(setPlayerName(result));
    return result;
  }

  useEffect(() => {
    return () => {
      setNewGameOptions("hack--pattern absolute top-0 right-0 h-full");
    };
  }, []);

  useEffect(() => {
    const storedAchievementsList = JSON.parse(
      localStorage.getItem("achievementsList")
    );
    // Если в localStorage отсутствует ключ "achievementsList"
    // то задаем начальное значение и сохраняем его в localStorage
    if (!storedAchievementsList) {
      localStorage.setItem(
        "achievementsList",
        JSON.stringify(achievementsListFresh)
      );
      // setAchievementsList(initialAchievementsList);
    } else {
      // setAchievementsList(storedAchievementsList);
      dispatch(achievementsStartReducer(storedAchievementsList));
    }
  }, []);

 

  return (
    <div>
      <Achievements />
      {startPage === true ? (
        <div>
          <span className="gamePreload text-center absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full cursor-pointer active">
            <img
              src="https://assets.codepen.io/217233/hack--headphones.svg"
              className="h-12 mb-3"
              alt="img"
            />
            <span className="text-2xl text-white">
              This game contains audio
            </span>
            <span
              onClick={() => {
                dispatch(startPageChanger());
                dispatch(newGameChanger());
                dispatch(setSoundOn());
              }}
              className="text-green"
            >
              Click anywhere to begin
            </span>
          </span>
          <div className={newGameOptions} style={{ right: "0px" }}>
            <img
              src="https://assets.codepen.io/217233/hack--pattern_2.png"
              alt="uknown"
            />
          </div>
        </div>
      ) : null}
      {newGame ? (
        <div
          className={`game_intro h-screen flex items-center z-30 absolute top-0 left-0 w-full ${
            achievements === false ? "active" : ""
          }`}
        >
          <div className={newGameOptions}>
            <img
              src="https://assets.codepen.io/217233/hack--pattern_2.png"
              alt="uknown"
            />
          </div>
          <div className="m-auto introWrapper flex items-center realtive z-10">
            <div className="gameInit active">
              <div className="game_intro__menu text-center flex flex-col">
                <div className="flash absolute bg-white w-full h-full left-0 top-0"></div>
                <div className="startLogoHack mb-2 flex justify-center">
                  <img
                    src="https://assets.codepen.io/217233/hackerLogoPart1.svg"
                    className="h-12 mb-1 -mr-1"
                    alt="img"
                  />
                  <img
                    src="https://assets.codepen.io/217233/hackerLogoPart1.svg"
                    className="h-12 mb-1"
                    alt="img"
                  />
                  <img
                    src="https://assets.codepen.io/217233/hackerLogoPart2.svg"
                    className="h-12 mb-1 ml-2"
                    alt="img"
                  />
                </div>
                <div className="menu  text-white text-xl space-y-1 mt-12">
                  <h3
                    onMouseOver={playSoundCardHover}
                    onMouseOut={stopSoundCardHover}
                    onClick={() => {
                      setNewGameOptions(
                        "hack--pattern absolute top-0 right-0 h-full active"
                      );
                      setNewGameOptionsStyle(
                        "game_intro__newgame text-white text-2xl active"
                      );
                    }}
                    className=""
                  >
                    New game
                  </h3>
                  <h3
                    onMouseOver={playSoundCardHover}
                    onMouseOut={stopSoundCardHover}
                    onClick={() => {
                      dispatch(activeAchievements(true));
                    }}
                    className=""
                  >
                    Achievements
                  </h3>
                </div>
              </div>
            </div>

            {/* <div className="game_intro__newgame text-white text-2xl"> */}
            <div className={newGameOptionsStyle}>
              <div className="inner">
                <h4
                  onMouseOver={playSoundCardHover}
                  onMouseOut={stopSoundCardHover}
                  onClick={() => {
                    setNewGameOptions(
                      "hack--pattern absolute top-0 right-0 h-full"
                    );
                    setNewGameOptionsStyle(
                      "game_intro__newgame text-white text-2xl"
                    );
                  }}
                  className="mb-8 cursor-pointer backArrow"
                >
                  <svg
                    fill="none"
                    height="21"
                    viewBox="0 0 24 21"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M4.15132 11.4688L11.2171 18.5329C11.3991 18.7148 11.5012 18.9615 11.5012 19.2188C11.5012 19.476 11.3991 19.7227 11.2171 19.9046C11.0352 20.0865 10.7885 20.1887 10.5313 20.1887C10.274 20.1887 10.0273 20.0865 9.8454 19.9046L1.12665 11.1859C1.03643 11.0959 0.964855 10.989 0.916018 10.8713C0.86718 10.7536 0.842041 10.6274 0.842041 10.5C0.842041 10.3726 0.86718 10.2464 0.916018 10.1287C0.964855 10.011 1.03643 9.90412 1.12665 9.81413L9.8454 1.09538C10.0273 0.913473 10.274 0.811279 10.5313 0.811279C10.7885 0.811279 11.0352 0.913473 11.2171 1.09538C11.3991 1.27728 11.5012 1.524 11.5012 1.78125C11.5012 2.03851 11.3991 2.28522 11.2171 2.46713L4.15132 9.53125H22.1562C22.4132 9.53125 22.6596 9.63332 22.8413 9.81499C23.0229 9.99667 23.125 10.2431 23.125 10.5C23.125 10.7569 23.0229 11.0033 22.8413 11.185C22.6596 11.3667 22.4132 11.4688 22.1562 11.4688H4.15132Z"
                      fillRule="evenodd"
                      fill="#fff"
                    ></path>
                  </svg>
                </h4>
                <h2 className="text-lg mb-2">Enter a network ID</h2>
                <div className="flex items-center relative">
                  <input
                    value={playerName}
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    id="targetID"
                    className="text-black mr-3"
                  />
                  <img
                    src="https://assets.codepen.io/217233/el_network.svg"
                    className="net absolute left-3"
                    alt=""
                  />
                  <button
                    onMouseOver={playSoundCardHover}
                    onMouseOut={stopSoundCardHover}
                    onClick={() => {
                      generateRandomString();
                    }}
                    className="random"
                  >
                    <svg
                      fill="none"
                      height="26"
                      viewBox="0 0 34 26"
                      width="34"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.3838 10.8321H32.9062C33.0092 10.8321 33.11 10.8615 33.1969 10.9168C33.2838 10.972 33.3531 11.0509 33.3968 11.1441C33.4404 11.2374 33.4566 11.3411 33.4435 11.4433C33.4303 11.5454 33.3883 11.6416 33.3224 11.7207L29.0612 16.836C29.0103 16.8969 28.9467 16.946 28.8748 16.9797C28.8028 17.0133 28.7244 17.0308 28.645 17.0308C28.5656 17.0308 28.4872 17.0133 28.4153 16.9797C28.3433 16.946 28.2797 16.8969 28.2289 16.836L23.9676 11.7207C23.9017 11.6416 23.8597 11.5454 23.8466 11.4433C23.8334 11.3411 23.8496 11.2374 23.8933 11.1441C23.9369 11.0509 24.0062 10.972 24.0931 10.9168C24.18 10.8615 24.2808 10.8321 24.3838 10.8321ZM0.541663 15.167H9.06413C9.1671 15.167 9.26792 15.1376 9.35479 15.0823C9.44166 15.0271 9.51099 14.9482 9.55466 14.8549C9.59832 14.7617 9.61452 14.6579 9.60135 14.5558C9.58817 14.4537 9.54618 14.3575 9.48029 14.2784L5.21905 9.16314C5.1682 9.10215 5.10456 9.05309 5.03265 9.01943C4.96074 8.98576 4.8823 8.96831 4.8029 8.96831C4.72349 8.96831 4.64506 8.98576 4.57315 9.01943C4.50123 9.05309 4.4376 9.10215 4.38675 9.16314L0.12551 14.2784C0.0596143 14.3575 0.0176224 14.4537 0.00445182 14.5558C-0.0087188 14.6579 0.00747695 14.7617 0.0511427 14.8549C0.0948084 14.9482 0.164136 15.0271 0.251009 15.0823C0.337881 15.1376 0.438701 15.167 0.541663 15.167Z"
                        fill="#00FFC2"
                      ></path>
                      <path
                        clipRule="evenodd"
                        d="M16.7238 2.16268C13.3599 2.16268 10.3515 3.69507 8.3639 6.10313C8.27515 6.21816 8.16418 6.31418 8.03757 6.38546C7.91097 6.45674 7.77133 6.50184 7.62694 6.51808C7.48256 6.53431 7.33639 6.52135 7.19712 6.47995C7.05785 6.43856 6.92832 6.36959 6.81624 6.27713C6.70416 6.18468 6.61182 6.07063 6.5447 5.94177C6.47758 5.81291 6.43706 5.67187 6.42554 5.52704C6.41402 5.3822 6.43175 5.23653 6.47766 5.09868C6.52357 4.96083 6.59673 4.83363 6.69279 4.72462C8.27978 2.80322 10.3853 1.37808 12.7588 0.618696C15.1323 -0.140687 17.674 -0.202351 20.0816 0.441036C22.4892 1.08442 24.6613 2.40579 26.3396 4.24797C28.0179 6.09014 29.1317 8.37561 29.5487 10.8325H27.3444C26.844 8.38505 25.5137 6.18547 23.5785 4.60572C21.6433 3.02597 19.2219 2.16298 16.7238 2.16268ZM6.10324 15.1675C6.51356 17.1716 7.48251 19.0187 8.89813 20.4954C10.3138 21.9721 12.1183 23.0182 14.1033 23.5128C16.0882 24.0074 18.1726 23.9303 20.1157 23.2905C22.0587 22.6506 23.781 21.4741 25.0837 19.8969C25.1725 19.7818 25.2835 19.6858 25.4101 19.6145C25.5367 19.5433 25.6763 19.4982 25.8207 19.4819C25.9651 19.4657 26.1112 19.4787 26.2505 19.52C26.3898 19.5614 26.5193 19.6304 26.6314 19.7229C26.7435 19.8153 26.8358 19.9294 26.9029 20.0582C26.97 20.1871 27.0106 20.3281 27.0221 20.473C27.0336 20.6178 27.0159 20.7635 26.97 20.9013C26.9241 21.0392 26.8509 21.1664 26.7548 21.2754C25.1678 23.1968 23.0624 24.6219 20.6888 25.3813C18.3153 26.1407 15.7736 26.2024 13.366 25.559C10.9585 24.9156 8.78635 23.5942 7.10805 21.752C5.42975 19.9099 4.31588 17.6244 3.89893 15.1675H6.10324Z"
                        fillRule="evenodd"
                        fill="#00FFC2"
                      ></path>
                    </svg>
                  </button>
                </div>
                <h2 className="text-lg mb-3 mt-8">Difficulty</h2>
                <Difficulty />
                <button
                  onMouseOver={playSoundCardHover}
                  onMouseOut={stopSoundCardHover}
                  onClick={() => {
                    startSound();
                    dispatch(newGameChangerFalse());
                  }}
                  className="hack bg-white"
                >
                  Hack
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ) : null} */}
    </div>
  );
};
export default StartPage;
