import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OneCard from "./OneCard";
import { useDrop } from "react-dnd";

import {
} from "../redux/features/stats/statsSlice.js";

const Deck = () => {

  const difficultyGame = useSelector((state) => state.stats.difficultyGame);
  const currentSetDeck = useSelector((state) => state.stats.currentSetDeck);
  const statusDetected = useSelector((state) => state.stats.statusDetected);
 
  const getDeckOneCard = () => {
    if (difficultyGame === "easy") {
      return currentSetDeck.map((item, index) => {
        return <OneCard easyDeck={item} key={index} index={index} />;
      });
    }
    if (difficultyGame === "medium") {
      return currentSetDeck.map((item, index) => {
        return <OneCard easyDeck={item} key={index} index={index} />;
      });
    }
    if (difficultyGame === "hard") {
      return currentSetDeck.map((item, index) => {
        return <OneCard easyDeck={item} key={index} index={index} />;
      });
    }
  };

  const dispatch = useDispatch();

  return (
    <div className="flex flex-col items-start relative justify-start">
      <div className="table_card flex mt-8">
        {difficultyGame !== "" && statusDetected === false
          ? getDeckOneCard()
          : null}
      </div>
    </div>
  );
};

export default Deck;
