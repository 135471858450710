import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TutorialPage from "./TutorialPage";

const Tutorial = () => {
  const tutorialInformation = useSelector(
    (state) => state.stats.tutorialInformation
  );
  const tutorialActive = useSelector((state) => state.stats.tutorialActive);

  const getTutorialPage = () => {
    return tutorialInformation.map((item, index) => {
      return <TutorialPage page={item} key={index} index={index} />;
    });
  };

  return (
    <div className={`tutorial fixed bg-red-100 inset-0 z-30 flex items-center justify-center ${tutorialActive ? "active" : ""}`}>
      {tutorialInformation.length > 0 ? getTutorialPage() : null}
    </div>
     );
};
export default Tutorial;
