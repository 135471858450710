import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Winner = ({}) => {
  const difficultyGame = useSelector((state) => state.stats.difficultyGame);
  const playerName = useSelector((state) => state.stats.playerName);
  const statusWinner = useSelector((state) => state.stats.statusWinner);

  return (
    <div className={`game_winner flex flex-col justify-center h-screen items-center ${statusWinner === true ? "active" : ""}`}>
      <img
        src="https://assets.codepen.io/217233/hack--pc.svg"
        className="h-6 mb-4"
        alt=""
      />
      <h2 className="text-3xl text-white w-full text-center mb-1">
        Target hacked
      </h2>
      <h4 className="text-lg text-white w-full text-center text-description">
        You won
      </h4>
      <div className="detected">
        <div className="div text-white my-12 flex flex-col justify-center text-center">
          <span>
            Game seed:
            <span className="text-green">{playerName}</span>
          </span>
          <span className="text-white">
            Difficulty:
            <span className="text-green">{difficultyGame}</span>
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <h3
          onClick={() => {
            window.location.reload();
          }}
          className="text-2xl text-white w-full text-center px-12"
        >
          New game
        </h3>
      </div>
    </div>
  );
};

export default Winner;
