import { createSlice } from "@reduxjs/toolkit";

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    //purple
    offensiveCards: [
      {
        id: 666,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
        cost: 12,
      },
      {
        id: 666,
        name: "Worm",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 4,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "Worm",
        cost: 18,
      },
      {
        id: 666,
        name: "Rootkit",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 5,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: "",
        triggerValue: 2,
        mode: "Rootkit",
        cost: 12,
      },
      {
        id: 666,
        name: "RAT",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 6,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: "",
        triggerValue: 2,
        mode: "RAT",
        cost: 15,
      },
      {
        id: 666,
        name: "Virus",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 5,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "Virus",
        cost: 23,
      },
    ],
    //blue
    defensiveCards: [
      {
        id: 666,
        name: "Cloak",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 8,
        attackValue: "",
        defValue: 8,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
        cost: 25,
      },
      {
        id: 666,
        name: "Spoofer",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 6,
        attackValue: "",
        defValue: 6,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
        cost: 25,
      },
      {
        id: 666,
        name: "Proxy",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 4,
        attackValue: "",
        defValue: 4,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
        cost: 12,
      },
      {
        id: 666,
        name: "Shell",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 2,
        attackValue: "",
        defValue: 2,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
        cost: 8,
      },
    ],
    //heal none
    healthCards: [
      {
        id: 666,
        name: "Defrag",
        text: "Replenish integrity points",
        classColor: "none",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "healCard",
        cost: 4,
      },
      {
        id: 666,
        name: "Defrag",
        text: "Replenish integrity points",
        classColor: "none",
        img: "",
        value: 6,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "healCard",
        cost: 8,
      },
      {
        id: 666,
        name: "Debug",
        text: "Replenish integrity points",
        classColor: "none",
        img: "",
        value: 8,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "healCard",
        cost: 10,
      },
    ],
    //yellow
    nodeCards: [
      {
        id: 20,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
      {
        id: 20,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
      {
        id: 20,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
      {
        id: 6,
        name: "Data",
        text: "Click to collect Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: "",
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      {
        id: 5,
        name: "Email account",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 6,
        attackValue: "",
        defValue: "",
        dataValue: 6,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 2,
        name: "Network switch",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 6,
        attackValue: 0,
        defValue: "",
        dataValue: 6,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      // {
      //   name: "FTP server",
      //   type: "node",
      // },
      {
        id: 8,
        name: "Web server",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      // {
      //   name: "Storage network",
      //   type: "node",
      // },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      // {
      //   name: "Cache",
      //   type: "node",
      // },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      {
        id: 3,
        name: "Bank account",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },

      {
        id: 16,
        name: "File system",
        text: "Use a data miner on this extract the data",
        classColor: "yellow",
        img: "",
        value: 8,
        attackValue: "",
        defValue: "",
        dataValue: 8,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },

      // {
      //   name: "Hotspot",
      //   type: "node",
      // },

      // {
      //   name: "Mail server",
      //   type: "node",
      // },

      {
        id: 14,
        name: "Terminal",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 10,
        attackValue: 0,
        defValue: "",
        dataValue: 10,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      // {
      //   name: "Crypto wallet",
      //   type: "node",
      // },
    ],
    //enemy red
    enemies: [
      {
        id: 1,
        name: "Gateway",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },

      {
        id: 8,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },

      {
        id: 8,
        name: "Database",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 5,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 5,
        mode: "enemy",
      },
      {
        id: 15,
        name: "SDL",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 6,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },

      {
        id: 17,
        name: "Virtual Machine",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
    ],
    bosses: [
      {
        id: 1,
        name: "Data Center",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 9,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 9,
        mode: "enemy",
      },
      {
        id: 1,
        name: "Security beacon",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 12,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 12,
        mode: "enemy",
      },
      {
        id: 1,
        name: "Mainframe",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 10,
        // attackValue: 6,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 10,
        mode: "enemy",
      },
      {
        id: 1,
        name: "Antivirus",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 8,
        // attackValue: 8,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 8,
        mode: "enemy",
      },

      {
        id: 1,
        name: "Firewall",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        // attackValue: 15,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 1,
        name: "Server",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 20,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 20,
        mode: "enemy",
      },
      {
        id: 1,
        name: "Subnet",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 9,
        attackValue: 4,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 9,
        mode: "enemy",
      },
    ],
    shops: [
      {
        id: 12,
        name: "Enumerate",
        text: "Improve your integrity",
        classColor: "none",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "hpUpgrade",
      },
      {
        id: 2,
        name: "Tor Browser",
        text: "Download new software",
        classColor: "none",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "shop",
      },
    ],
    win: [
      {
        id: 18,
        name: "",
        text: "",
        classColor: "Win",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: 0,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "Win",
      },
    ],
    //upgrade orange
    relicCards: [
      {
        id: 666,
        name: "Brute module",
        text: "Brute Force have +1 penetration",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        cost: 12,
        targets: ["player.maxInventory"],
        operator: ["+="],
        change: [1],
      },
      {
        id: 666,
        name: "Ram module",
        text: "Increase tech deck capacity by 1",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        cost: 12,
        targets: ["player.maxInventory"],
        operator: ["+="],
        change: [1],
      },
      {
        id: 666,
        name: "Lag switch",
        text: "Increase max integrity by 5",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        targets: ["player.maxHealth"],
        operator: ["+="],
        change: [5],
        cost: 15,
      },
      {
        id: 666,
        name: "Premium proxy",
        text: "Increase max integrity by 10",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        targets: ["player.maxHealth"],
        operator: ["+="],
        change: [10],
        cost: 25,
      },
      {
        id: 666,
        name: "Sensitive data",
        text: "1 extra choice in shops",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        targets: ["player.shopCardTotal"],
        operator: ["+="],
        change: [1],
        cost: 15,
      },
      {
        id: 3,
        name: "Remote Bypass",
        text: "RATs have +1 penetration",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        cost: 15,
      },
      {
        id: 3,
        name: "Automatic concealment",
        text: "Virus has +1 penetration",
        classColor: "orange",
        targets: ['player.boosts["Virus"]'],
        affects: 4,
        affectGroup: "offensiveCards",
        operator: ["+="],
        change: [1],
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        cost: 15,
      },
      {
        id: 3,
        name: "Worm AI",
        text: "Worms have +1 penetration",
        classColor: "orange",
        targets: ['player.boosts["Virus"]'],
        affects: 4,
        affectGroup: "offensiveCards",
        operator: ["+="],
        change: [1],
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        cost: 15,
      },
      {
        id: 3,
        name: "Multilang rootkits",
        text: "Rootkits have +1 penetration",
        classColor: "orange",
        targets: ['player.boosts["Virus"]'],
        affects: 4,
        affectGroup: "offensiveCards",
        operator: ["+="],
        change: [1],
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        cost: 15,
      },

      {
        id: 3,
        name: "YO-YO attacks",
        text: "DDoS has +1 uses",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
        cost: 15,
      },
    ],
    //red
    bfCard: [
      {
        id: 18,
        name: "Brute force",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 1,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "bruteForce",
      },
      {
        id: 22,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
      },
      {
        id: 20,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
    ],
    easyDeck: [
      {
        id: 1,
        name: "Gateway",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 2,
        name: "Tor Browser",
        text: "Download new software",
        classColor: "none",
        img: "",
        value: 2,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "shop",
      },
      {
        id: 3,
        name: "Remote Bypass",
        text: "RATs have +1 penetration",
        classColor: "orange",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 1,
        bruteForce: 6,
        triggerValue: 1,
        mode: "upgrade",
      },
      {
        id: 4,
        name: "Cloak",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 8,
        attackValue: "",
        defValue: 8,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
      },
      {
        id: 5,
        name: "Email account",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 6,
        attackValue: "",
        defValue: "",
        dataValue: 6,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "data",
      },
      {
        id: 6,
        name: "Data",
        text: "Click to collect Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: "",
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "freeData",
      },
      {
        id: 7,
        name: "Data",
        text: "Click to collect Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: "",
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "freeData",
      },
      {
        id: 8,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 9,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 10,
        name: "Email account",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 10,
        attackValue: "",
        defValue: "",
        dataValue: 10,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "data",
      },
      {
        id: 11,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 12,
        name: "Enumerate",
        text: "Improve your integrity",
        classColor: "none",
        img: "",
        value: "",
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "hpUpgrade",
      },
      {
        id: 13,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
      },
      {
        id: 14,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 15,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
      {
        id: 16,
        name: "File system",
        text: "Use a data miner on this extract the data",
        classColor: "yellow",
        img: "",
        value: 8,
        attackValue: "",
        defValue: "",
        dataValue: 8,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "data",
      },
      {
        id: 17,
        name: "Data",
        text: "Click to collect Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: "",
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "freeData",
      },
      {
        id: 18,
        name: "",
        text: "",
        classColor: "Win",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: 0,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "Win",
      },
    ],
    mediumDeck: [
      {
        id: 1,
        name: "Gateway",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 2,
        name: "Network switch",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 6,
        attackValue: 0,
        defValue: "",
        dataValue: 6,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 3,
        name: "Bank account",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 4,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
      },
      {
        id: 5,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "dataMiner",
      },
      {
        id: 6,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      {
        id: 7,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 8,
        name: "Web server",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 9,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 10,
        name: "Cloak",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 8,
        attackValue: 0,
        defValue: 8,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "blockCard",
      },
      {
        id: 11,
        name: "Tor Browser",
        text: "Download new software",
        classColor: "none",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "shop",
      },
      {
        id: 12,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      {
        id: 14,
        name: "Terminal",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 10,
        attackValue: 0,
        defValue: "",
        dataValue: 10,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 15,
        name: "SDL",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 6,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 16,
        name: "SDL",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 6,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 17,
        name: "Virtual Machine",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 18,
        name: "",
        text: "",
        classColor: "Win",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: 0,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "Win",
      },
    ],
    hardDeck: [
      {
        id: 1,
        name: "Gateway",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 1,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 2,
        name: "Network switch",
        text: "Hack this node to progress",
        classColor: "yellow",
        img: "",
        value: 6,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 3,
        name: "Bank accoun",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 4,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
      },
      {
        id: 5,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "dataMiner",
      },
      {
        id: 6,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: 4,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },
      {
        id: 7,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 8,
        name: "Web server",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 4,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 9,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 10,
        name: "Cloak",
        text: "Blocks incoming attacks",
        classColor: "blue",
        img: "",
        value: 8,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "blockCard",
      },
      {
        id: 11,
        name: "Tor Browser",
        text: "Download new software",
        classColor: "none",
        img: "",
        value: 0,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "shop",
      },
      {
        id: 12,
        name: "Router",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 2,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 13,
        name: "Data",
        text: "Click to collect. Spend on the dark web",
        classColor: "yellow",
        img: "",
        value: 5,
        attackValue: 0,
        defValue: "",
        dataValue: 5,
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "freeData",
      },

      {
        id: 14,
        name: "Terminal",
        text: "Use a data miner on this to extract the data",
        classColor: "yellow",
        img: "",
        value: 10,
        attackValue: 0,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 0,
        mode: "data",
      },
      {
        id: 15,
        name: "SDL",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 6,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 16,
        name: "SDL",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 2,
        attackValue: 6,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 2,
        mode: "enemy",
      },
      {
        id: 17,
        name: "Virtual Machine",
        text: "Hack this node to progress",
        classColor: "red",
        img: "",
        value: 4,
        attackValue: 3,
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 4,
        mode: "enemy",
      },
      {
        id: 18,
        name: "",
        text: "",
        classColor: "Win",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: 0,
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "Win",
      },
    ],
    techDeck: [
      {
        id: 18,
        name: "Brute force",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 1,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "bruteForce",
      },
      {
        id: 19,
        name: "Defrag",
        text: "Replenish integrity points",
        classColor: "none",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "healCard",
      },
      {
        id: 20,
        name: "Data miner",
        text: "Use this on a node to mine its data",
        classColor: "yellow",
        img: "",
        value: 0,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "dataMiner",
      },
      {
        id: 21,
        name: "Proxy",
        text: "Use a data miner on this to extract the data",
        classColor: "blue",
        img: "",
        value: 4,
        attackValue: "",
        defValue: 4,
        dataValue: "",
        reRoll: "",
        bruteForce: "",
        triggerValue: 1,
        mode: "blockCard",
      },
      {
        id: 22,
        name: "DDoS",
        text: "Deplete target nodes resistance",
        classColor: "purple",
        img: "",
        value: 3,
        attackValue: "",
        defValue: "",
        dataValue: "",
        reRoll: 2,
        bruteForce: "",
        triggerValue: 2,
        mode: "DDoS",
      },
    ],
    tutorialInformation: [
      {
        title: "Welcome to Hack",
        text: `In hack your goal is simple. Work your way through each stage's deck
        until you reach the end. Do this by using software in your tech
        deck, hacking data nodes and purchasing new upgrades.`,
        comment: "Let’s go over some of the basics",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--one.gif)",
        isActive: true,
      },
      {
        title: "The tech deck",
        text: `At the bottom of your screen you will find your tech deck. These are
        your playable cards and will help you move through each stage. You
        may only have a certain number of techs in hand at any one time so
        choose carefully what you take and leave.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--two.gif)",
      },
      {
        title: "Formatting",
        text: `   To the right of your tech deck is the format slot. Dropping a card
        from your tech deck into this slot will remove it from play and free
        up space in your hand. Once the card is formatted, it can no longer
        be retrieved.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--three.gif)",
      },
      {
        title: "The hacker",
        text: `You take role of the hacker. Your hacker card is in the top left of
        the user interace. The card contains your current data and your
        current integrity points.`,
        comment: `If your integrity points fall to zero, you are detected and the game
        is over.`,
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--four.gif",
      },
      {
        title: "The firewall",
        text: `To the right of your hacker is the firewall. The firewalls purpose
        is to block incoming damage from offensive programs in the stage
        deck. To load a module, simply drag one from your tech deck into the
        module slot. The total amount a firewall module can block is displayed on the card.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--five.gif)",
      },
      {
        title: "The stage deck",
        text: `This is the main stage deck. You must work your way through all of
        the cards in order to progress to the next stage. The hacker can
        interact with the various cards by dragging tech onto them or by
        simply clicking on them.`,
        comment: `Let's take a look at some of the types of cards`,
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--six.gif)",
      },
      {
        title: "Offensive cards",
        text: `These cards will attack you and reduce your integrity points. You
        can see between the firewall module and the enemy what the intents
        of the enemy are. You must terminate an offensive card in order to
        progress. To do this, drag offensive cards onto the enemy from your
        tech deck. The amount of damage you do will depend on the
        penetration number of the used card.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--eight.gif)",
      },
      {
        title: "Data",
        text: `Data cards can be clicked to add them to your data store. This data
        can then be used on the dark web to purchase additional software,
        hardware or upgrades.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--nine.gif)",
      },
      {
        title: "Data miner and data nodes",
        text: `Within the stage deck you will find data nodes containing an amount
        of data. Use a data miner on them to collect its data, or simply
        click it to skip and progress to the next card`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--ten.gif)",
      },
      {
        title: "Healing",
        text: `There are several ways in which you can restore your integrity. You
        can play restoration cards from your tech deck, or using any of the
        games enumeration cards`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--eleven.gif)",
      },
      {
        title: "Start hacking",
        text: `That's the basics. As you start to play you'll learn strategies
        around picking the best upgrades for your current situation. If you
        need help again, click the help icon in the top right. Good luck and
        have fun.`,
        comment: "",
        isActive: "",
        gif: "url(https://assets.codepen.io/217233/hack--tutorial--one.gif)",
      },
    ],
    achievementsList: [
      {
        name: "Maximum penetration",
        complete: false,
        description: "Get any program to 9 or more penetration",
      },

      {
        name: "Snowden",
        complete: false,
        description: "Attempt to hack the CIA",
      },
      //+
      {
        name: "Script kiddy",
        complete: false,
        description: "Collect 1 upgrades in one run",
      },
      //+
      {
        name: "Red hat",
        complete: false,
        description: "Collect 4 upgrades in one run",
      },
      //+
      {
        name: "Black hat",
        complete: false,
        description: "Collect 6 upgrades in one run",
      },
      //+
      {
        name: "Elite hacker",
        complete: false,
        description: "Collect 10 upgrades in one run",
      },

      {
        name: "Impenetrable",
        complete: false,
        description: "Get any firewall module to 12 or more resistance",
      },
      //+
      {
        name: "Gigabyte",
        complete: false,
        description: "Collect 10 or more data",
      },
      //+
      {
        name: "Teabyte",
        complete: false,
        description: "Collect 40 or more data",
      },
      //+
      {
        name: "Petabyte",
        complete: false,
        description: "Collect 100 or more data",
      },
      //+
      {
        name: "Mine, all mine",
        complete: false,
        description: "Mine a network node",
      },
      //+
      {
        name: "One down",
        complete: false,
        description: "Terminate a node",
      },
      //+
      {
        name: "Top up",
        complete: false,
        description: "Heal yourself",
      },
      //+
      {
        name: "n00b",
        complete: false,
        description: "Get detected",
      },
      //+
      {
        name: "Its a UNIX system!",
        complete: false,
        description: "Complete a stage",
      },
      {
        name: "Jackpot",
        complete: false,
        description: "Mine a node worth 14 or more data",
      },
//+
      {
        name: "Kitted",
        complete: false,
        description: "Have a max tech deck capacity of 8 or more",
      },
      //+
      {
        name: "Data dump",
        complete: false,
        description: "Hack a Data Center",
      },
      //+
      {
        name: "Not so secure",
        complete: false,
        description: "Hack a Security beacon",
      },
      //+
      {
        name: "My kung fu is stronger",
        complete: false,
        description: "Hack a Mainframe",
      },
      //+
      {
        name: "Antivirus down",
        complete: false,
        description: "Hack an Antivirus",
      },
      //+
      {
        name: "Through the fire and flame",
        complete: false,
        description: "Hack a Firewall",
      },
      //+
      {
        name: "Youve been served",
        complete: false,
        description: "Hack a Server",
      },
      //+
      {
        name: "Piece of cake",
        complete: false,
        description: "Hack a Subnet",
      },
      //+
      {
        name: "We're in",
        complete: false,
        description: "Successfully hack an easy target",
      },
      //+
      {
        name: "I am invincible",
        complete: false,
        description: "Successfully hack a medium target",
      },
      //+
      {
        name: "There is no spoon",
        complete: false,
        description: "Successfully hack a hard target",
      },
      //+
      {
        name: "I know Kung Fu",
        complete: false,
        description: "Download something from the dark web",
      },
      //+
      {
        name: "Dictionary attack",
        complete: false,
        description: "Increase your Brute Force penetration",
      },
      //+
      {
        name: "Absolute unit",
        complete: false,
        description: "Have a max integrity of 30 or more",
      },
      //+
      {
        name: "Fort Knox",
        complete: false,
        description: "Have a max integrity of 50 or more",
      },
      //+
      {
        name: "They are on to you",
        complete: false,
        description: "Take more than 6 damage in one hit",
      },
      //+
      {
        name: "Skin of your teeth",
        complete: false,
        description: "Complete a stage with just 1 integrity remaining",
      },
      //+
      {
        name: "Digital don",
        complete: false,
        description: "Complete a stage with full integrity",
      },
      //+
      {
        name: "They all on to you",
        complete: false,
        description: "Take more than 12 damage in one hit",
      },
    ],
    achievementsListFresh: [
      {
        name: "Maximum penetration",
        complete: false,
        description: "Get any program to 9 or more penetration",
      },

      {
        name: "Snowden",
        complete: false,
        description: "Attempt to hack the CIA",
      },
      //+
      {
        name: "Script kiddy",
        complete: false,
        description: "Collect 1 upgrades in one run",
      },
      //+
      {
        name: "Red hat",
        complete: false,
        description: "Collect 4 upgrades in one run",
      },
      //+
      {
        name: "Black hat",
        complete: false,
        description: "Collect 6 upgrades in one run",
      },
      //+
      {
        name: "Elite hacker",
        complete: false,
        description: "Collect 10 upgrades in one run",
      },

      {
        name: "Impenetrable",
        complete: false,
        description: "Get any firewall module to 12 or more resistance",
      },
      //+
      {
        name: "Gigabyte",
        complete: false,
        description: "Collect 10 or more data",
      },
      //+
      {
        name: "Teabyte",
        complete: false,
        description: "Collect 40 or more data",
      },
      //+
      {
        name: "Petabyte",
        complete: false,
        description: "Collect 100 or more data",
      },
      //+
      {
        name: "Mine, all mine",
        complete: false,
        description: "Mine a network node",
      },
      //+
      {
        name: "One down",
        complete: false,
        description: "Terminate a node",
      },
      //+
      {
        name: "Top up",
        complete: false,
        description: "Heal yourself",
      },
      //+
      {
        name: "n00b",
        complete: false,
        description: "Get detected",
      },
      //+
      {
        name: "Its a UNIX system!",
        complete: false,
        description: "Complete a stage",
      },
      {
        name: "Jackpot",
        complete: false,
        description: "Mine a node worth 14 or more data",
      },

      {
        name: "Kitted",
        complete: false,
        description: "Have a max tech deck capacity of 8 or more",
      },
      //+
      {
        name: "Data dump",
        complete: false,
        description: "Hack a Data Center",
      },
      //+
      {
        name: "Not so secure",
        complete: false,
        description: "Hack a Security beacon",
      },
      //+
      {
        name: "My kung fu is stronger",
        complete: false,
        description: "Hack a Mainframe",
      },
      //+
      {
        name: "Antivirus down",
        complete: false,
        description: "Hack an Antivirus",
      },
      //+
      {
        name: "Through the fire and flame",
        complete: false,
        description: "Hack a Firewall",
      },
      //+
      {
        name: "Youve been served",
        complete: false,
        description: "Hack a Server",
      },
      //+
      {
        name: "Piece of cake",
        complete: false,
        description: "Hack a Subnet",
      },
      //+
      {
        name: "We're in",
        complete: false,
        description: "Successfully hack an easy target",
      },
      //+
      {
        name: "I am invincible",
        complete: false,
        description: "Successfully hack a medium target",
      },
      //+
      {
        name: "There is no spoon",
        complete: false,
        description: "Successfully hack a hard target",
      },
      //+
      {
        name: "I know Kung Fu",
        complete: false,
        description: "Download something from the dark web",
      },
      //+
      {
        name: "Dictionary attack",
        complete: false,
        description: "Increase your Brute Force penetration",
      },
      //+
      {
        name: "Absolute unit",
        complete: false,
        description: "Have a max integrity of 30 or more",
      },
      //+
      {
        name: "Fort Knox",
        complete: false,
        description: "Have a max integrity of 50 or more",
      },
      //+
      {
        name: "They are on to you",
        complete: false,
        description: "Take more than 6 damage in one hit",
      },
      //+
      {
        name: "Skin of your teeth",
        complete: false,
        description: "Complete a stage with just 1 integrity remaining",
      },
      //+
      {
        name: "Digital don",
        complete: false,
        description: "Complete a stage with full integrity",
      },
      //+
      {
        name: "They all on to you",
        complete: false,
        description: "Take more than 12 damage in one hit",
      },
    ],
    achievementsListUpd: [],
    difficultyGame: "easy",
    playerName: "Hacker",
    soundOn: false,
    currentDifficultyGame: "",
    stage: 0,
    currentSetDeck: [],
    curentTechDeck: [],
    currentShop: [],
    defSlot: "",
    upgradeTech: [],
    upgradeTechCount: 0,
    maxHp: 20,
    realHp: 20,
    dataHacker: 0,
    block: 0,
    mode: "",
    countsTableCard: 0,
    counstTechDeck: 5,
    maxCounstTechDeck: 6,
    upgradeDdos: 0,
    upgradeBf: 0,
    upgradeRat: 0,
    upgradeWorm: 0,
    upgradeRootkit: 0,
    upgradeVirus: 0,
    upgradeAttack: 0,
    actualAttack: 0,
    currentAttackDmg: 0, // ???
    alarmDiv: "hidden",
    startPage: true,
    newGame: false,
    tutorialActive: false,
    countsTutorial: 0,
    shop: false,
    hpUpgrade: false,
    achievements: false,
    styleOneCard: "cardOne",
    statusDetected: false,
    statusWinner: false,
    shakeAnimation: false,
    shakeDmg: "",
    achievInfoText: { name: "", description: "" },
    achievInfoDiv: false,
  },
  reducers: {
    achievementsStartReducer: (state, action) => {
      state.achievementsList = action.payload;
    },
    achievementsDivReducer: (state, action) => {
      state.achievInfoDiv = action.payload;
    },

    achievementsReducer: (state, action) => {
      const updatedAchievements = state.achievementsList.map(
        (achievement, index) => {
          if (achievement.name == action.payload) {
            if (achievement.complete == false) {
              state.achievInfoText = {
                name: achievement.name,
                description: achievement.description,
              };
              state.achievementsList[index] = {
                ...achievement,
                complete: true,
              };
              state.achievInfoDiv = true;
            }

            return { ...achievement, complete: true };
          }
          return achievement;
        }
      );
      localStorage.setItem(
        "achievementsList",
        JSON.stringify(updatedAchievements)
      );
    },

    checkMode: (state, action) => {
      state.mode = action.payload;
    },
    loseRealHp: (state, action) => {
      const { block, realHp, upgradeAttack } = state;
      const damage = action.payload + upgradeAttack;
      state.currentAttackDmg = damage;
      if (damage > block) {
        state.block = 0;
        const hp = realHp - damage + block;
        state.realHp = hp < 0 ? 0 : hp;
        return state;
      }
      state.block -= damage;
      return state;
      // const damage = action.payload
      // const fullDmg = damage+state.upgradeAttack
      // if (state.block === 0) {
      //     state.realHp = state.realHp - fullDmg
      // }
      // if (state.block > 0) {
      //     state.block = state.block - fullDmg
      // }
      // if (state.block < 0) {
      //     state.realHp = state.realHp + state.block
      //     state.block = 0
      // }
    },
    actualAttackSetter: (state, action) => {
      state.actualAttack = action.payload;
    },
    increaseRealHp: (state, action) => {
      const { realHp, maxHp } = state;
      const overHeal = action.payload + realHp;
      if (overHeal > maxHp) {
        state.realHp = maxHp;
      } else {
        state.realHp = state.realHp + action.payload;
      }
    },
    increaseMaxHp: (state, action) => {
      state.maxHp = state.maxHp + action.payload;
    },
    increaseDataHacker: (state, action) => {
      state.dataHacker = state.dataHacker + action.payload;
    },
    decreaseDataHacker: (state, action) => {
      state.dataHacker = state.dataHacker - action.payload;
    },
    increaseBlock: (state, action) => {
      state.block = action.payload;
      // console.log("block", state.block);
    },
    //не используется
    countsTableCards: (state, action) => {
      state.countsTableCard += 1;
    },
    pushTechDeck: (state, action) => {
      state.curentTechDeck = [...state.curentTechDeck, action.payload];
      return state;
    },

    countsTechCards: (state, action) => {
      state.counstTechDeck += 1;
    },
    minusTechCards: (state, action) => {
      state.counstTechDeck -= 1;
    },
    activatedDefSlot: (state, action) => {
      state.defSlot = action.payload;
    },
    // deleteTechDeckCard: (state, action) => {
    //   const { curentTechDeck } = state;
    //   const name = action.payload;
    //   const newTechDeck = curentTechDeck.filter((item) => item.name !== name);
    //   state.curentTechDeck = newTechDeck;

    //   // const indexCard = action.payload
    //   // state.techDeck = [
    //   //     ...state.techDeck.slice(0, indexCard),
    //   //     ...state.techDeck.slice(indexCard + 1)
    //   // ];
    // },
    deleteTechDeckCard: (state, action) => {
      const { curentTechDeck } = state;
      const name = action.payload;
      const indexToRemove = curentTechDeck.findIndex(
        (item) => item.name === name
      );
      if (indexToRemove !== -1) {
        const newTechDeck = [...curentTechDeck];
        newTechDeck.splice(indexToRemove, 1);
        state.curentTechDeck = newTechDeck;
      }
    },
    increaseUpgradeAttack: (state, action) => {
      const text = action.payload;
      if (text === "Brute Force have +1 penetration") {
        state.upgradeBf += 1;
      }
      if (text === "Increase max integrity by 10") {
        state.maxHp += 10;
      }
      if (text === "Increase tech deck capacity by 1") {
        state.maxCounstTechDeck += 1;
      }
      if (text === "Increase max integrity by 5") {
        state.maxHp += 5;
      }
      if (text === "Premium proxy") {
        state.maxHp += 10;
      }
      if (text === "1 extra choice in shops") {
        // state.maxCounstTechDeck += 1
      }
      if (text === "RATs have +1 penetration") {
        state.upgradeRat += 1;
      }
      if (text === "DDoS has +1 uses") {
        state.upgradeDdos += 1;
      }
      if (text === "Virus has +1 penetration") {
        state.upgradeVirus += 1;
      }
      if (text === "Worms have +1 penetration") {
        state.upgradeWorm += 1;
      }
      if (text === "Rootkits have +1 penetration") {
        state.upgradeRootkit += 1;
      }
    },
    setAlarmDiv: (state, action) => {
      state.alarmDiv = action.payload;
    },
    startPageChanger: (state, action) => {
      state.startPage = false;
    },
    newGameChanger: (state, action) => {
      state.newGame = true;
    },
    newGameChangerFalse: (state, action) => {
      state.newGame = false;
    },
    installDifficultyGame: (state, action) => {
      state.difficultyGame = action.payload;
    },
    setPlayerName: (state, action) => {
      state.playerName = action.payload;
    },
    addUpgradeTech: (state, action) => {
      state.upgradeTech = [...state.upgradeTech, action.payload];
      state.upgradeTechCount += 1;
      return state;
    },
    activeTutorial: (state, action) => {
      state.tutorialActive = action.payload;
    },
    numCountsTutorial: (state, action) => {
      state.countsTutorial += 1;
    },
    resetCountsTutorial: (state, action) => {
      state.countsTutorial = 0;
    },
    activeShop: (state, action) => {
      state.shop = action.payload;
    },
    activeAchievements: (state, action) => {
      state.achievements = action.payload;
    },

    activeHpUpgrade: (state, action) => {
      state.hpUpgrade = action.payload;
    },
    resetGame: (state, action) => {
      state.stage = 0;
      state.maxHp = 20;
      state.realHp = 20;
      state.defSlot = "";
      state.upgradeTech = [];
      state.upgradeTechCount = 0;
      state.dataHacker = 0;
      state.block = 0;
      state.mode = "";
      state.countsTableCard = 0;
      state.counstTechDeck = 5;
      state.upgradeAttack = 0;
      state.curentTechDeck = action.payload;

      // state.currentSetDeck = []

      // state.currentSetDeck = action.payload;

      // state.easyDeck = state.easyDeck
    },
    setCurrentDeck: (state, action) => {
      state.currentSetDeck = action.payload;
    },
    setCurentTechDeck: (state, action) => {
      state.curentTechDeck = action.payload;
    },

    setStatusDetected: (state, action) => {
      state.statusDetected = action.payload;
    },
    setSoundOn: (state, action) => {
      state.soundOn = !state.soundOn;
    },
    setStatusWinner: (state, action) => {
      state.statusWinner = action.payload;
    },
    setShakeAnimation: (state, action) => {
      state.shakeAnimation = action.payload;
    },
    setShakeDmg: (state, action) => {
      state.shakeDmg = action.payload;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setCurentShop: (state, action) => {
      state.currentShop = action.payload;
    },
    hpChanger: (state, action) => {
      // let id = action.payload
      // state.list[id]['counts'] += 1
      // state.sumCount += 1
    },
    countRerollValue: (state, action) => {
      const name = action.payload;
      const array = state.curentTechDeck;
      if (name === "DDoS") {
        const index = array.findIndex((item) => item.mode === name);
        if (index !== -1) {
          const newDeck = [...array];
          newDeck[index].reRoll -= 1;
          state.curentTechDeck = newDeck;
        }
      }
      if (name === "RAT") {
        const index = array.findIndex((item) => item.mode === name);
        if (index !== -1) {
          const newDeck = [...array];
          newDeck[index].reRoll -= 1;
          state.curentTechDeck = newDeck;
        }
      }
      if (name === "Virus") {
        const index = array.findIndex((item) => item.mode === name);
        if (index !== -1) {
          const newDeck = [...array];
          newDeck[index].reRoll -= 1;
          state.curentTechDeck = newDeck;
        }
      }
      if (name === "Worm") {
        const index = array.findIndex((item) => item.mode === name);
        if (index !== -1) {
          const newDeck = [...array];
          newDeck[index].reRoll -= 1;
          state.curentTechDeck = newDeck;
        }
      }
      if (name === "Rootkit") {
        const index = array.findIndex((item) => item.mode === name);
        if (index !== -1) {
          const newDeck = [...array];
          newDeck[index].reRoll -= 1;
          state.curentTechDeck = newDeck;
        }
      }
    },
  },
});

export const {
  checkMode,
  loseRealHp,
  increaseMaxHp,
  increaseDataHacker,
  increaseBlock,
  countsTableCards,
  pushTechDeck,
  countsTechCards,
  minusTechCards,
  activatedDefSlot,
  deleteTechDeckCard,
  increaseUpgradeAttack,
  increaseRealHp,
  hpChanger,
  blockChanger2,
  setAlarmDiv,
  startPageChanger,
  newGameChanger,
  newGameChangerFalse,
  installDifficultyGame,
  setPlayerName,
  addUpgradeTech,
  activeTutorial,
  numCountsTutorial,
  resetCountsTutorial,
  activeShop,
  activeHpUpgrade,
  resetGame,
  setCurrentDeck,
  setStatusDetected,
  setCurentTechDeck,
  activeAchievements,
  setSoundOn,
  setStatusWinner,
  setShakeAnimation,
  setShakeDmg,
  setStage,
  setCurentShop,
  countRerollValue,
  decreaseDataHacker,
  achievementsReducer,
  achievementsStartReducer,
  achievementsDivReducer,
  actualAttackSetter,
} = statsSlice.actions;

export default statsSlice.reducer;
