import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import {
  activatedDefSlot,
  minusTechCards,
  increaseBlock,
  deleteTechDeckCard,
} from "../redux/features/stats/statsSlice.js";
import hackDefensiveEquipped from "../sound/hackDefensiveEquipped.mp3";

const DefensiveSlot = () => {
  const defSlot = useSelector((state) => state.stats.defSlot);
  const block = useSelector((state) => state.stats.block);
  const [stroke, setStroke] = useState(0);
  const oneProcentValueStroke = 673 / block;
  const shakeAnimation = useSelector((state) => state.stats.shakeAnimation);
  const currentAttackDmg = useSelector((state) => state.stats.currentAttackDmg);
  const actualAttack = useSelector((state) => state.stats.actualAttack);
  const soundOn = useSelector((state) => state.stats.soundOn);

  const countStroke = () => {
    if (defSlot.value === block) {
      setStroke(0);
    }
    if (defSlot.value !== block) {
      setStroke(oneProcentValueStroke);
    }
  };

  const dispatch = useDispatch();

  const [{ isOver }, dragCardOnZone] = useDrop(() => ({
    accept: "blockCard",
    drop: (item) => setBlockCard(item.techDeckInfo),
   
  }));

  const setBlockCard = (techDeckInfo) => {
    //console.log("ADD", techDeckInfo);
    // dispatch(activatedDedSlot(''))
    if (soundOn === true) {
      const audio = new Audio(hackDefensiveEquipped);
      audio.play();
    }
    dispatch(activatedDefSlot(techDeckInfo));
    dispatch(increaseBlock(techDeckInfo.value));
    dispatch(deleteTechDeckCard(techDeckInfo.name));
    dispatch(minusTechCards());
  };

  useEffect(() => {
    countStroke();
  }, [block]);

  return (
  
    <div className="animationWrap" ref={dragCardOnZone}>
      {block > 0 ? (
        <div style={{ pointerEvents: "all" }}>
          <div
            className="back_card_test_click card relative droppable flex items-center justify-center"
            onClick={() => {}}
          ></div>
          <div
            className={`${
              shakeAnimation === true
                ? `front_card_click slot rounded-lg flex items-center relative mt-1 shake`
                : `front_card_click slot rounded-lg flex items-center relative mt-1`
            }`}
            style={{ marginTop: "0" }}
            onClick={() => {}}
          >
            <div className="card_text cardholder relative right-0">
              <div
                data-accepts="enemy"
                data-id="0"
                className="card relative droppable flex items-center justify-center card--defensive"
                style={{ width: "135px" }}
              >
                <div className="cardDragWrapper">
                  <svg
                    fill="none"
                    height="215"
                    viewBox="0 0 135 215"
                    width="135"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-0 top-0"
                  >
                    <rect
                      strokeDasharray="673"
                      strokeDashoffset={`${stroke}`}
                      height="213"
                      rx="11"
                      strokeLinecap="round"
                      strokeWidth="2"
                      stroke="#00D1FF"
                      width="133"
                      x="1"
                      y="1"
                      className="transition-all duration-300"
                    ></rect>
                  </svg>
                  <span
                    className={`${
                      shakeAnimation === true
                        ? `damageNumberHit text-white text-2xl absolute top-0 left-12 flex gap-2 items-center`
                        : `damageNumber text-white text-2xl absolute top-0 left-12 flex gap-2 items-center `
                    }`}
                  >
                    <svg
                      fill="none"
                      height="36"
                      viewBox="0 0 44 51"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6"
                    >
                      <path
                        d="M42.8214 9.11818C39.2605 8.18351 35.7817 6.969 32.4186 5.48636C29.1098 4.07477 25.9157 2.41629 22.8643 0.525455L22 0L21.1514 0.540909C18.1 2.43175 14.9059 4.09022 11.5971 5.50182C8.22846 6.97996 4.74437 8.18931 1.17857 9.11818L0 9.41182V22.3009C0 42.9945 21.2614 50.7373 21.4657 50.8145L22 51L22.5343 50.8145C22.7543 50.8145 44 43.01 44 22.3009V9.41182L42.8214 9.11818Z"
                        fill="#00D1FF"
                      ></path>
                    </svg>

                    {/* {currentAttackDmg} */}
                    {currentAttackDmg <= 1 ? 1 : actualAttack}
                  </span>
                  <div className="flex gap-4 justify-center items-center">
                    <div className="relative flex items-center justify-center w-12 h-12 mb-2">
                      <span className="text-2xl relative z-10 text-blue">
                        {block}
                      </span>
                      <svg
                        fill="none"
                        height="51"
                        viewBox="0 0 44 51"
                        width="44"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute"
                      >
                        <path
                          d="M42.8214 9.11818C39.2605 8.18351 35.7817 6.969 32.4186 5.48636C29.1098 4.07477 25.9157 2.41629 22.8643 0.525455L22 0L21.1514 0.540909C18.1 2.43175 14.9059 4.09022 11.5971 5.50182C8.22846 6.97996 4.74437 8.18931 1.17857 9.11818L0 9.41182V22.3009C0 42.9945 21.2614 50.7373 21.4657 50.8145L22 51L22.5343 50.8145C22.7543 50.8145 44 43.01 44 22.3009V9.41182L42.8214 9.11818Z"
                          fill="#00D1FF"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h2 className="text-xl text-center w-full mt-3 px-4">
                    {defSlot.name}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          data-accepts="defensive"
          className="slot rounded-lg card card--firewall droppable relative flex items-center justify-center"
        >
          <div className="w-full h-full items-center justify-center relative">
            <span className="flex gap-2 flex-col items-center h-full justify-center">
              <div className="relative flex items-center justify-center w-12 h-12 mb-2">
                <span className="text-2xl relative z-10 text-blue"></span>
                <svg
                  fill="none"
                  height="51"
                  viewBox="0 0 44 51"
                  width="44"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute"
                >
                  <path
                    fill="#092830"
                    d="M42.8214 9.11818C39.2605 8.18351 35.7817 6.969 32.4186 5.48636C29.1098 4.07477 25.9157 2.41629 22.8643 0.525455L22 0L21.1514 0.540909C18.1 2.43175 14.9059 4.09022 11.5971 5.50182C8.22846 6.97996 4.74437 8.18931 1.17857 9.11818L0 9.41182V22.3009C0 42.9945 21.2614 50.7373 21.4657 50.8145L22 51L22.5343 50.8145C22.7543 50.8145 44 43.01 44 22.3009V9.41182L42.8214 9.11818Z"
                  ></path>
                </svg>
              </div>
              <span className="line-height-small text-lightblue px-4 text-center">
                No module loaded
              </span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default DefensiveSlot;
