import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  resetGame,
  setStatusDetected,
  achievementsReducer
} from "../redux/features/stats/statsSlice.js";

const Detected = ({}) => {
  const difficultyGame = useSelector((state) => state.stats.difficultyGame);
  const playerName = useSelector((state) => state.stats.playerName);
  const realHp = useSelector((state) => state.stats.realHp);
  const techDeck = useSelector((state) => state.stats.techDeck);
  const stage = useSelector((state) => state.stats.stage);

  const reRender = () => {
    dispatch(setStatusDetected(false));
    if (difficultyGame === "easy") {
      dispatch(resetGame(techDeck));
      
    }
    if (difficultyGame === "medium") {
      dispatch(resetGame(techDeck));

    }
    if (difficultyGame === "hard") {
      dispatch(resetGame(techDeck));

    }
  };

 useEffect(() => {
  if (realHp <= 0){
    dispatch(setStatusDetected(true));
    dispatch(achievementsReducer("n00b"));
  }
  },[realHp]);
  

  const dispatch = useDispatch();

  return (
    // <div  className="game_gameoverman flex flex-col justify-center h-screen items-center active">
    <div
      className={`game_gameoverman flex flex-col justify-center h-screen items-center ${
        realHp <= 0 ? "active" : ""
      }`}
    >
      <svg
        fill="none"
        height="50"
        viewBox="0 0 22 22"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
        className="h-16 mb-2"
      >
        <path
          clipRule="evenodd"
          d="M9.88187 2.91315C10.3425 1.99184 11.6573 1.99184 12.1179 2.91315L19.8819 18.441C20.2974 19.2721 19.6931 20.25 18.7638 20.25H3.23597C2.30674 20.25 1.70237 19.2721 2.11794 18.441L9.88187 2.91315ZM13.4596 2.24233C12.4461 0.215447 9.55367 0.215447 8.54023 2.24233L0.776295 17.7702C-0.137944 19.5987 1.19167 21.75 3.23597 21.75H18.7638C20.8081 21.75 22.1377 19.5987 21.2235 17.7702L13.4596 2.24233ZM11.5583 14.0035L12.3246 7.48978C12.4179 6.6968 11.7984 6.00005 10.9999 6.00005C10.2015 6.00005 9.58187 6.6968 9.67516 7.48978L10.4415 14.0035C10.4748 14.2866 10.7148 14.5 10.9999 14.5C11.285 14.5 11.525 14.2866 11.5583 14.0035ZM11.9999 17C11.9999 17.5523 11.5522 18 10.9999 18C10.4476 18 9.9999 17.5523 9.9999 17C9.9999 16.4477 10.4476 16 10.9999 16C11.5522 16 11.9999 16.4477 11.9999 17Z"
          fillRule="evenodd"
          fill="#00FFC2"
        ></path>
      </svg>
      <h2 className="text-3xl text-white w-full text-center mb-1">Detected</h2>
      <h4 className="text-lg text-white w-full text-center text-description">
        It's game over man...game over!
      </h4>
      <div className="detected">
        <h5 className="text-2xl text-white w-full text-center mt-12 mb-1 text-green">
          You failed on stage {stage} of 9
        </h5>
        <div className="div text-white mb-12 flex flex-col justify-center text-center">
          <span>
            Game seed:
            <span className="text-green">{playerName}</span>
          </span>
          <span className="text-white">
            Difficulty:
            <span className="text-green">{difficultyGame}</span>
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <h3
          onClick={() => {
            reRender();
          }}
          className="text-2xl text-white w-full text-center mb-2 px-12"
        >
          Try again
          <span className="text-sm">(Same seed)</span>
        </h3>
        <h3
          onClick={() => {
            window.location.reload();
          }}
          className="text-2xl text-white w-full text-center px-12"
        >
          Abandon
        </h3>
      </div>
    </div>
  );
};
export default Detected;
