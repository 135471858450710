import { configureStore,applyMiddleware } from '@reduxjs/toolkit'
import statsSliceReducer from './features/stats/statsSlice.js';



export default configureStore({
	reducer: {
		stats: statsSliceReducer,
	},
	

})

