import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UpgradeCard from "./UpgradeCard";


const Footer = () => {
  const upgradeTech = useSelector((state) => state.stats.upgradeTech);
  const [visible, setVisible] = useState(false);
  const [classStyle, setClassStyle] = useState();

  const clickFooter = () => {
    if (visible === false) {
      setClassStyle("min");
    } else {
      setClassStyle("");
    }
    setVisible(!visible);
  };
  const getUpgradeTech = () => {
    return upgradeTech.map((item, index) => {
      return <UpgradeCard upgrade={item} key={index} index={index} />
  });}

  return (
    <div className={`game_stage__relics text-center ${classStyle} active`}>
      <div
        onClick={() => {
          clickFooter();
        }}
        className="relics_minimize uppercase text-white"
      >
        <span>Upgrades</span>
        <span className="relicCount">{upgradeTech.length}</span>
      </div>
      <h4 className="text-2xl mb-8 text-white">Upgrades</h4>
      {upgradeTech.length === 0 ? (
        <h3 className="text-white text-md">No collected upgrades yet</h3>
      ) : (
        <div className="gap-8 grid grid-cols-8">
            {upgradeTech.length > 0? getUpgradeTech() :null}
        </div>
      )}
    </div>
  );
};
export default Footer;
