import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import store from "./redux/store";
import GameBoard from "./components/GameBoard";
import StartPage from "./components/StartPage";

function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <div className="App">
          {/* <div className="screenCap absolute inset-0 flex items-center justify-center">
            <div className="flex flex-col">
              <img
                className="h-12"
                src="https://assets.codepen.io/217233/hack--screen.svg"
                alt=""
              />
              <span className="text-white mt-4 px-10 text-center">
                This game is optimized for desktop resolutions 1300px x 700px
                and up.
                <p>Please play on a larger resolution.</p>
              </span>
            </div>
          </div> */}
          <StartPage />
          <GameBoard />
        </div>
      </DndProvider>
    </Provider>
  );
}

export default App;
