import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  activeHpUpgrade,
  increaseRealHp,
  increaseMaxHp,
} from "../redux/features/stats/statsSlice.js";
import cardHover from "../sound/hackCardHoverFX.wav";
import hackTakeFX from "../sound/hackTakeFX.mp3";
import hackHealFX from "../sound/hackHealFX.wav";

const HpUpgrade = () => {
  const hpMax = useSelector((state) => state.stats.maxHp);
  const realHp = useSelector((state) => state.stats.realHp);
  const hpUpgrade = useSelector((state) => state.stats.hpUpgrade);
  const anyHp = (hpMax / 100) * 25;
  const soundOn = useSelector((state) => state.stats.soundOn);
  const [cardHoverMouseOver, setCardHoverMouseOver] = useState(false);
  const dispatch = useDispatch();
  const playSoundCardHover = () => {
    if (soundOn === true) {
      const audio = new Audio(cardHover);
      audio.play();
      setCardHoverMouseOver(true);
    }
  };

  const stopSoundCardHover = () => {
    setCardHoverMouseOver(false);
  };

  const healSound = () => {
    if (soundOn === true) {
      const audio = new Audio(hackHealFX);
      audio.play();
    }
  };


  
  return (
    <div
      className={`game_enumerate flex flex-col h-screen items-center justify-center  ${
        hpUpgrade ? "active" : ""
      }`}
    >
      <h2 className="text-3xl text-white w-full text-center mb-1">Enumerate</h2>
      <h5 className="text-lg text-white w-full text-center mb-12 text-description">
        Make a choice
      </h5>
      <div>
        <div className="flex gap-2 items-center flex mb-20">
          <img
            src="https://assets.codepen.io/217233/hack-integrityicon.png"
            className="h-5"
            alt=""
          />
          <span className="flex items-end text-white">
            <span className="text-2xl">{realHp}</span>
            <span
              className="text-md text-lightgrey"
              style={{ color: "rgb(53, 70, 74)" }}
            >
              /{hpMax}
            </span>
          </span>
        </div>
      </div>
      <div className="flex gap-4">
        <div
          onMouseOver={playSoundCardHover}
          onMouseOut={stopSoundCardHover}
          onClick={() => {
            //console.log("recovery25%");
            dispatch(activeHpUpgrade(false));
            dispatch(increaseRealHp(Math.round(anyHp)));
            healSound()
          }}
          className="w-60 cursor-pointer p-3 selection text-center flex flex-col justify-center items-center"
        >
          <svg
            fill="none"
            height="36"
            viewBox="0 0 36 36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 mb-2"
          >
            <path d="M13 12H23V24H13V12Z" fill="#00FFC2"></path>
            <path
              d="M32.4 7.2C32.4 5.2146 30.7854 3.6 28.8 3.6H25.2V0H21.6V3.6H14.4V0H10.8V3.6H7.2C5.2146 3.6 3.6 5.2146 3.6 7.2V10.8H0V14.4H3.6V21.6H0V25.2H3.6V28.8C3.6 30.7854 5.2146 32.4 7.2 32.4H10.8V36H14.4V32.4H21.6V36H25.2V32.4H28.8C30.7854 32.4 32.4 30.7854 32.4 28.8V25.2H36V21.6H32.4V14.4H36V10.8H32.4V7.2ZM7.2 28.8V7.2H28.8L28.8036 28.8H7.2Z"
              fill="#00FFC2"
            ></path>
          </svg>
          <h4 className="text-2xl text-white w-full mb-1">Recover</h4>
          <p className="text-description">
            Recover 25% of your integrity points
          </p>
        </div>
        <div
          onMouseOver={playSoundCardHover}
          onMouseOut={stopSoundCardHover}
          onClick={() => {
            //console.log("+5maxHp");
            dispatch(activeHpUpgrade(false));
            dispatch(increaseMaxHp(5));
          }}
          className="w-60 cursor-pointer p-3 selection text-center flex flex-col justify-center items-center"
        >
          <svg
            fill="none"
            height="36"
            viewBox="0 0 36 36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 mb-2"
          >
            <path d="M13 12H23V24H13V12Z" fill="#00FFC2"></path>
            <path
              d="M32.4 7.2C32.4 5.2146 30.7854 3.6 28.8 3.6H25.2V0H21.6V3.6H14.4V0H10.8V3.6H7.2C5.2146 3.6 3.6 5.2146 3.6 7.2V10.8H0V14.4H3.6V21.6H0V25.2H3.6V28.8C3.6 30.7854 5.2146 32.4 7.2 32.4H10.8V36H14.4V32.4H21.6V36H25.2V32.4H28.8C30.7854 32.4 32.4 30.7854 32.4 28.8V25.2H36V21.6H32.4V14.4H36V10.8H32.4V7.2ZM7.2 28.8V7.2H28.8L28.8036 28.8H7.2Z"
              fill="#00FFC2"
            ></path>
          </svg>
          <h4 className="text-2xl text-white w-full mb-1">Fortify</h4>
          <p className="text-description">Gain +5 max integrity</p>
        </div>
      </div>
    </div>
  );
};
export default HpUpgrade;
