import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OneCardOnHand from "./OneCardOnHand";
import { useDrop } from "react-dnd";
import {
  activatedDefSlot,
  minusTechCards,
  increaseBlock,
  deleteTechDeckCard,
} from "../redux/features/stats/statsSlice.js";
import hackTrashFX from "../sound/hackTrashFX.mp3";


const TechDeck = () => {
  const techDeck = useSelector((state) => state.stats.techDeck);
  const counstTechDeck = useSelector((state) => state.stats.counstTechDeck);
  const statusDetected = useSelector((state) => state.stats.statusDetected);
  const curentTechDeck = useSelector((state) => state.stats.curentTechDeck);
  const soundOn = useSelector((state) => state.stats.soundOn);
  const maxCounstTechDeck = useSelector((state) => state.stats.maxCounstTechDeck);
  
  const [testEasyDeck, setTestEasyDeck] = useState(curentTechDeck);

  const getDeckOneCard = () => {
    return curentTechDeck.map((item, index) => {
      return <OneCardOnHand techDeck={item} key={index} index={index} />;
    });
  };
  const dispatch = useDispatch();

  const [{ isOver }, dragCardOnZone] = useDrop(() => ({
    accept: ["blockCard", "DDoS","RAT","Rootkit","Worm", "Virus","data","dataMiner","healCard"],
    drop: (item) => deleteCard(item.techDeckInfo),
 
  }));
  

  const deleteCard = (techDeckInfo) => {
    //console.log("del", techDeckInfo);
    dispatch(deleteTechDeckCard(techDeckInfo.name));
    dispatch(minusTechCards());
    if (soundOn === true) {
      const audio = new Audio(hackTrashFX);
      audio.play();
    }

    
  };

 

  return (
    <div className="flex justify-between w-full gap-8">
      <div className="w-full">
        <div className="gsc_hand flex flex-col">
          <div className="flex items-center gap-3 mb-6">
            <h4 className="text-md text-white w-20">Tech Deck</h4>
            <div className="h-px w-full bg-grey"></div>
            <div className="text-white flex w-12 items-end justify-end">
              <span className="text-2xl">{counstTechDeck}</span>
              <span className="text_grey text-lightgrey text-md">/{maxCounstTechDeck}</span>
            </div>
          </div>
          <div className="flex gap-4">
            {curentTechDeck.length > 0 && statusDetected === false
              ? getDeckOneCard()
              : null}
          </div>
        </div>
      </div>
      <div className="gsc_trash">
        <h4 className="text-md mb-8 text-white mt-2">Format</h4>
        <div
          ref={dragCardOnZone}
          data-accepts="any"
          className="card_drag card card--format slot droppable"
        ></div>
      </div>
    </div>
  );
};

export default TechDeck;
